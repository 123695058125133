import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import PostSection from '../components/PostSection'
import PostCategoriesNav from '../components/PostCategoriesNav'
import Footer from '../components/Footer'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = posts => {
  const now = Date.now()
  return posts.filter(post => Date.parse(post.date) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'tags'
  const byCategory = post =>
    post.tags &&
    post.tags.filter(cat => cat.tag === title).length
  return isCategory ? posts.filter(byCategory) : posts
}

// Export Template for use in CMS preview
class TagIndexTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.handler = this.handler.bind(this);
        this.state = {
            searchTerm: ''
        };
    }

    handler(searchTerm) {
        this.setState({
            searchTerm: searchTerm
        });
    }

    render() {
      let { title, subtitle, featuredImage, posts = [], postCategories = [], enableSearch = true, contentType, searchTerm = ''} = this.props;

      console.log(this.state.searchTerm)
      return (
        <Location>
          {({ location }) => {
            let filteredPosts =
              posts && !!posts.length
                ? byCategory(byDate(posts), title, contentType)
                : []

            return (
              <main className="Blog">
                <PageHeader
                  title={title}
                  subtitle={subtitle}
                  backgroundImage={featuredImage}
                />

                {!!postCategories.length && (
                  <section className="section thin">
                    <div className="container">
                      <PostCategoriesNav enableSearch categories={postCategories} handler={this.handler} index='/recipes/' location={location} />
                    </div>
                  </section>
                )}

                {!!posts.length && (
                  <section className="section">
                    <div className="container">
                      <PostSection posts={filteredPosts.filter(post =>
                                            post.frontmatter.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                                          )} loadMoreTitle="Mais Receitas" ad={[]} />
                    </div>
                  </section>
                )}
              </main>
            )
          }}
        </Location>
    )
  }
}

// Export Default TagIndex for front-end
const TagIndex = ({ data: { page, posts, postCategories } }) => {
  const publishedPosts = posts.edges.filter(post => post.node.frontmatter.status == 'Published');

  return (
    <div>
      <TagIndexTemplate
        {...page}
        {...page.fields}
        {...page.frontmatter}
        posts={publishedPosts.map(post => ({
          ...post.node,
          ...post.node.frontmatter,
          ...post.node.fields
        }))}
        postCategories={postCategories.edges.map(post => ({
          ...post.node,
          ...post.node.frontmatter,
          ...post.node.fields
        }))}
      />
      <Footer />
    </div>
)}

export default TagIndex

export const pageQuery = graphql`
  ## Query for TagIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query TagIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "recipes" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            status
            categories {
              category
            }
            tags {
              tag
            }
            featuredImage
          }
        }
      }
    }
    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "tags" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
